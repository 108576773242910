export const meleeWeaponsData = [
    {
        "id": 1,
        "name": "Mace",
        "codeName": "bw_1h_mace",
        "description": "weapon_keyword_high_damage,weapon_keyword_damage_over_time,weapon_keyword_wide_sweeps",
        "canWield": "bw_scholar,bw_adept,bw_unchained",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 2,
        "name": "Dagger",
        "codeName": "bw_dagger",
        "description": "weapon_keyword_damage_over_time,weapon_keyword_crowd_control,weapon_keyword_fast_attacks",
        "canWield": "bw_scholar,bw_adept,bw_unchained",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 40
    },
    {
        "id": 3,
        "name": "Flame Sword",
        "codeName": "bw_flame_sword",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_damage_over_time",
        "canWield": "bw_scholar,bw_adept,bw_unchained",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 4,
        "name": "Sword",
        "codeName": "bw_sword",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_versatile",
        "canWield": "bw_scholar,bw_adept,bw_unchained",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 8,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 5,
        "name": "Axe",
        "codeName": "dr_1h_axe",
        "description": "weapon_keyword_high_damage,weapon_keyword_armour_piercing,weapon_keyword_shield_breaking",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 6,
        "name": "Hammer",
        "codeName": "dr_1h_hammer",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 9,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 7,
        "name": "Great Axe",
        "codeName": "dr_2h_axe",
        "description": "weapon_keyword_high_damage,weapon_keyword_shield_breaking,weapon_keyword_armour_piercing",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 5,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 8,
        "name": "Two-Handed Hammer",
        "codeName": "dr_2h_hammer",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_armour_piercing",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 9,
        "name": "War Pick",
        "codeName": "dr_2h_pick",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_heavy_charge",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 10,
        "name": "Dual Axes",
        "codeName": "dr_dual_wield_axes",
        "description": "weapon_keyword_high_damage,weapon_keyword_fast_attacks,weapon_keyword_armour_piercing",
        "canWield": "dr_slayer",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 4,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 11,
        "name": "Axe & Shield",
        "codeName": "dr_shield_axe",
        "description": "weapon_keyword_high_damage,weapon_keyword_armour_piercing,weapon_keyword_powerful_blocks",
        "canWield": "dr_ironbreaker,dr_ranger,dr_engineer",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 10,
        "blockMovespeed": 1,
        "blockInnerCost": 0.2,
        "blockOuterCost": 2,
        "blockAngle": 180
    },
    {
        "id": 12,
        "name": "Hammer & Shield",
        "codeName": "dr_shield_hammer",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_powerful_blocks",
        "canWield": "dr_ironbreaker,dr_ranger,dr_engineer",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 10,
        "blockMovespeed": 1,
        "blockInnerCost": 0.2,
        "blockOuterCost": 2,
        "blockAngle": 180
    },
    {
        "id": 13,
        "name": "Flail",
        "codeName": "es_1h_flail",
        "description": "weapon_keyword_ignore_shields,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "wh_zealot,wh_captain,wh_bountyhunter",
        "dodgeDistance": 1.15,
        "dodgeSpeed": 1.15,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 14,
        "name": "Mace",
        "codeName": "es_1h_mace",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 9,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 15,
        "name": "Sword",
        "codeName": "es_1h_sword",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_versatile",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 8,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 16,
        "name": "Two-Handed Hammer",
        "codeName": "es_2h_hammer",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_armour_piercing",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 17,
        "name": "Two-Handed Sword",
        "codeName": "es_2h_sword",
        "description": "weapon_keyword_high_damage,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1.1,
        "dodgeSpeed": 1.1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 18,
        "name": "Executioner Sword",
        "codeName": "es_2h_sword_executioner",
        "description": "weapon_keyword_headshotting,weapon_keyword_wide_sweeps,weapon_keyword_high_damage",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 19,
        "name": "Halberd",
        "codeName": "es_halberd",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_armour_piercing,weapon_keyword_versatile",
        "canWield": "es_huntsman,es_knight,es_mercenary",
        "dodgeDistance": 0.95,
        "dodgeSpeed": 1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 20,
        "name": "Mace & Shield",
        "codeName": "es_mace_shield",
        "description": "weapon_keyword_wide_sweeps,weapon_keyword_crowd_control,weapon_keyword_powerful_blocks",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 10,
        "blockMovespeed": 1,
        "blockInnerCost": 0.2,
        "blockOuterCost": 2,
        "blockAngle": 180
    },
    {
        "id": 21,
        "name": "Sword & Shield",
        "codeName": "es_sword_shield",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_powerful_blocks",
        "canWield": "es_huntsman,es_knight,es_mercenary,es_questingknight",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 10,
        "blockMovespeed": 1,
        "blockInnerCost": 0.2,
        "blockOuterCost": 2,
        "blockAngle": 120
    },
    {
        "id": 22,
        "name": "Sword",
        "codeName": "we_1h_sword",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_versatile",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 1.5,
        "blockAngle": 90
    },
    {
        "id": 23,
        "name": "Glaive",
        "codeName": "we_2h_axe",
        "description": "weapon_keyword_high_damage,weapon_keyword_armour_piercing,weapon_keyword_fast_attacks",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1,
        "dodgeSpeed": 1,
        "stamina": 4,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 24,
        "name": "Two-Handed Sword",
        "codeName": "we_2h_sword",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_versatile",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.1,
        "dodgeSpeed": 1.1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 25,
        "name": "Dual Daggers",
        "codeName": "we_dual_wield_daggers",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_headshotting,weapon_keyword_damage_over_time",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.35,
        "dodgeSpeed": 1.35,
        "stamina": 4,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 40
    },
    {
        "id": 26,
        "name": "Sword & Dagger",
        "codeName": "we_dual_wield_sword_dagger",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_versatile",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.75,
        "blockOuterCost": 1.5,
        "blockAngle": 90
    },
    {
        "id": 27,
        "name": "Dual Swords",
        "codeName": "we_dual_wield_swords",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 1,
        "blockAngle": 90
    },
    {
        "id": 28,
        "name": "Spear",
        "codeName": "we_spear",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_versatile,weapon_keyword_high_agility",
        "canWield": "we_shade,we_maidenguard,we_waywatcher,we_thornsister",
        "dodgeDistance": 1.15,
        "dodgeSpeed": 1.15,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 180
    },
    {
        "id": 29,
        "name": "Axe",
        "codeName": "wh_1h_axe",
        "description": "weapon_keyword_high_damage,weapon_keyword_armour_piercing,weapon_keyword_shield_breaking",
        "canWield": "wh_zealot,wh_bountyhunter,wh_captain",
        "dodgeDistance": 1.2,
        "dodgeSpeed": 1.2,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 30,
        "name": "Falchion",
        "codeName": "wh_1h_falchion",
        "description": "weapon_keyword_headshotting,weapon_keyword_high_damage,weapon_keyword_versatile",
        "canWield": "wh_zealot,wh_bountyhunter,wh_captain",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 4,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 31,
        "name": "Two-Handed Sword",
        "codeName": "wh_2h_sword",
        "description": "weapon_keyword_high_damage,weapon_keyword_wide_sweeps,weapon_keyword_crowd_control",
        "canWield": "wh_zealot,wh_bountyhunter,wh_captain",
        "dodgeDistance": 1.1,
        "dodgeSpeed": 1.1,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.5,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 32,
        "name": "Rapier",
        "codeName": "wh_fencing_sword",
        "description": "weapon_keyword_fast_attacks,weapon_keyword_headshotting,weapon_keyword_versatile",
        "canWield": "wh_bountyhunter,wh_captain,wh_zealot",
        "dodgeDistance": 1.25,
        "dodgeSpeed": 1.25,
        "stamina": 6,
        "blockMovespeed": 1,
        "blockInnerCost": 0.25,
        "blockOuterCost": 2,
        "blockAngle": 90
    },
    {
        "id": 33,
        "name": "Mace and Sword",
        "codeName": "es_mace_and_sword",
        "canWield": "es_mercenary,es_knight,es_huntsman,es_questingknight"
    },
    {
        "id": 34,
        "name": "Spear",
        "codeName": "es_spear",
        "canWield": "es_mercenary,es_huntsman"
    },
    {
        "id": 35,
        "name": "Elven Axe",
        "codeName": "we_axe",
        "canWield": "we_shade,we_maidenguard,we_waywatcher"
    },
    {
        "id": 36,
        "name": "Spear and Shield",
        "codeName": "we_spear_and_shield",
        "canWield": "we_shade,we_maidenguard,we_waywatcher"
    },
    {
        "id": 37,
        "name": "Dual Hammers",
        "codeName": "dr_hammers",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer"
    },
    {
        "id": 38,
        "name": "Throwing Axes",
        "codeName": "dr_throwing_axes",
        "canWield": "dr_slayer"
    },
    {
        "id": 39,
        "name": "Axe and Falchion",
        "codeName": "wh_axe_and_falchion",
        "canWield": "wh_zealot,wh_bountyhunter,wh_captain"
    },
    {
        "id": 40,
        "name": "Billhook",
        "codeName": "wh_billhook",
        "canWield": "wh_zealot,wh_bountyhunter,wh_captain"
    },
    {
        "id": 41,
        "name": "Crowbill",
        "codeName": "bw_crowbill",
        "canWield": "bw_scholar,bw_adept,bw_unchained"
    },
    {
        "id": 42,
        "name": "Flaming Flail",
        "codeName": "bw_flail",
        "canWield": "bw_scholar,bw_adept,bw_unchained"
    },
    {
        "id": 43,
        "name": "Bretonnian Sword and Shield",
        "codeName": "es_1h_sword_shield_breton",
        "canWield": "es_questingknight"
    },
    {
        "id": 44,
        "name": "Bretonnian Longsword",
        "codeName": "es_bastard_sword",
        "canWield": "es_questingknight, es_mercenary, es_huntsman, es_knight"
    },
    {
        "id": 45,
        "name": "Cog Hammer",
        "codeName": "dr_2h_cog_hammer",
        "canWield": "dr_ironbreaker,dr_slayer,dr_ranger,dr_engineer"
    },
    {
        "id": 46,
        "name": "Spear & Shield",
        "codeName": "es_deus_01",
        "canWield": "es_mercenary, es_huntsman, es_knight"
    }
]