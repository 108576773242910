export const traitsData = 
    { 
        "melee": [
            {
                "id": "1",
                "name":"Heroic Intervention",
                "description":"Assisting an ally under attack restores 15 temporary health for both players."
            },
            {
                "id": "2",
                "name":"Off Balance",
                "description":"Blocking an attack increases the amount of damage the attacker takes by 20% for 5 seconds."
            },
            {
                "id": "3",
                "name":"Opportunist",
                "description":"Increases push strength by 50% when used against an attacking enemy."
            },
            {
                "id": "4",
                "name":"Parry",
                "description":"Timed blocks reduce stamina cost by 100%."
            },
            {
                "id": "5",
                "name":"Resourceful Combatant",
                "description":"Melee critical hits reduce the cooldown of your Career Skill by 5%. This effect can only trigger every 4 seconds."
            },
            {
                "id": "6",
                "name":"Swift Slaying",
                "description":"Critical hits increase attack speed by 20% for 5 seconds​."
            }
        ],
        "range": [
            {
                "id": "1",
                "name":"Barrage",
                "description":"Consecutive attacks against the same targets boost attack power by 5% for 5 seconds."
            },
            {
                "id": "2",
                "name":"Conservative Shooter",
                "description":"Headshots replenish 1 ammunition."
            },
            {
                "id": "3",
                "name":"Heat Sink",
                "description":"Critical hits refund the overcharge cost of the attack.",
                "heat":"yes"
            },
            {
                "id": "4",
                "name":"Hunter",
                "description":"Critical hits increase attack power by 25% against targets with the same armor class for 10 seconds."
            },
            {
                "id": "5",
                "name":"Inspirational Shot",
                "description":"Headshots restore stamina to nearby allies."
            },
            {
                "id": "6",
                "name":"Resourceful Sharpshooter",
                "description":"Ranged critical hits reduce the cooldown of your Career Skill by 4%. This effect can only trigger every 4 seconds."
            },
            {
                "id": "7",
                "name":"Scrounger",
                "description":"Critical hits restore 5% of maximum ammunition. Can trigger once per attack."
            },
            {
                "id": "8",
                "name":"Thermal Equalizer",
                "description":"Weapon generates 20% less overheat.​",
                "heat":"yes"
            }
        ],
        "necklace": [
            {
                "id": "1",
                "name":"Barkskin",
                "description":"Taking damages reduces the damage you take from subsequent sources by 40% for 2 seconds. This effect can only trigger every 2 seconds."
            },
            {
                "id": "2",
                "name":"Hand of Shallya",
                "description":"Healing an ally with medical supplies also heals you for 50% of your missing health."
            },
            {
                "id": "3",
                "name":"Healer's Touch",
                "description":"25% chance to not consume healing item on use."
            },
            {
                "id": "4",
                "name":"Natural Bond",
                "description":"Passively regenerates 1 health every 5 seconds. Healing from First Aid Kits and Healing Draughts are converted to temporary health."
            },
            {
                "id": "5",
                "name":"Boon of Shallya",
                "description":"Increases effectiveness of healing on you by 30%."
            }
        ],
        "charm": [
            {
                "id": "1",
                "name":"Concoction",
                "description":"Drinking a potion grants the effect of all other potions. Duration reduced by 50%."
            },
            {
                "id": "2",
                "name":"Decanter",
                "description":"Increases the duration of potion effects by 50%."
            },
            {
                "id": "3",
                "name":"Home Brewer",
                "description":"25% chance to not consume potion on use."
            },
            {
                "id": "4",
                "name":"Proxy",
                "description":"Consuming a potion spreads the effect to the nearest ally​."
            }
        ],
        "trinket": [
            {
                "id": "1",
                "name":"Explosive Ordinance",
                "description":"Increases explosion radius by 50%."
            },
            {
                "id": "2",
                "name":"Grenadier",
                "description":"25% chance to not consume a used bomb."
            },
            {
                "id": "3",
                "name":"Shrapnel",
                "description":"Grenades cause hit enemies to take 20% increased damage for 10 seconds​ (Does not stack with itself)."
            }
        ]
    }
